import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { useFormik } from "formik"
import { getUserInfo, updateBasicInfoDetail } from "../../../../services/user-basicinfo/user-basicinfo"
import { toast } from "react-toastify"
import { useAuth } from "../../../../Contexts/auth-context"
import { getCountries } from "../../../../services/career/career-service"
import { getAllCity } from "../../../../services/student/student-profile"
import api from "../../../../services/axiosService"
import apiHeader from "../../../../services/apiHeader"

const EditBasicInfo = () => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const [contryData, setCountryData] = useState([])
  const [cityData, setCityData] = useState([])
  const getAllCountries = async () => {
    const res = await api.get(
      "country/getAllCountry",

      {
        headers: apiHeader(),
        params: { page_size: 200, page_number: 0 },
      }
    )

    if (res.data?.data?.records) {
      setCountryData(res.data?.data?.records)
    }
  }
  const getAllCities = async () => {
    const res = await getAllCity()
    if (res && res.data?.data?.records) {
      setCityData(res && res.data?.data?.records)
    }
  }
  const getUserInformation = async () => {
    await getUserInfo().then((res) => {
      if (res && res?.data?.data) {
        setFieldValue(res?.data?.data)
        setFieldValue("fname", res?.data?.data?.fname)
        setFieldValue("lname", res?.data?.data?.lname)
        // setFieldValue("fname",res?.data?.data?.fname)
        setFieldValue("designation", res?.data?.data?.designation)
        setFieldValue("gender", res?.data?.data?.gender)
        setFieldValue("home_city", res?.data?.data?.home_address?.city)
        setFieldValue("home_country", res?.data?.data?.home_address?.country)
        setFieldValue("email", res?.data?.data?.email)
        setFieldValue("phone", res?.data?.data?.phone)
        setFieldValue("salutation", res?.data?.data?.salutation)
      }
    })
  }
  useEffect(() => {
    getAllCities()
    getAllCountries()
    getUserInformation()
  }, [])

  let validationSchema = Yup.object({
    fname: Yup.string().required(),
    lname: Yup.string().required(),
    salutation: Yup.string(),
    designation: Yup.string(),
    gender: Yup.string(),
    home_country: Yup.string(),
    home_city: Yup.string(),
    email: Yup.string(),
    phone: Yup.string(),
  })
  const initialValues = {
    fname: undefined,
    lname: undefined,
    salutation: undefined,
    designation: undefined,
    organization: user?.organization ? user?.organization : "",
    gender: undefined,
    home_country: undefined,
    home_city: undefined,
    email: undefined,
    phone: undefined,
  }
  const onSubmit = async () => {
    let payload = {
      fname: values?.fname,
      lname: values?.lname,
      salutation: values?.salutation,
      designation: values?.designation,
      organization: user?.organization ? user?.organization : "",
      gender: values?.gender,
      home_country: values?.home_country,
      home_city: values?.home_city,
      email: values?.email,
      phone: values?.phone,
    }
    await updateBasicInfoDetail(payload).then((res) => {
      const { status, message } = res?.data?.meta
      if (status) {
        toast.success(message)
        resetForm()
        navigate("/user/personal-info")
      }
    })
  }
  const { handleChange, handleSubmit, values, setValues, setFieldValue, errors, resetForm } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  return (
    <React.Fragment>
      <div className="flex-1">
        <div className="d-flex justify-content-between align-items-start pt-4 mt-2 mb-4">
          <div className="welcome-title d-flex flex-wrap align-items-center">
            <p onClick={() => navigate("/user/personal-info")} className="btn-circle-back"></p>
            <h1 className="ms-3 m-0 text-start">Update Basic Information</h1>
          </div>
        </div>
        <div className="row sd_formsec bg-white m-0 mb-4">
          <div className="d-flex flex-column justify-content-between align-items-center mx-auto max-630 pt-4 pt-sm-5 pb-5 px-0 aos-init aos-animate">
            <form className="lstCustForm w-100" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <label className="form-label m-0 mb-2 font-16 font-500 w-100">Salutation</label>
                  <div className="row">
                    <div className="col-md-2 col-sm-3 col-auto mb-30">
                      <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                        <input type="radio" name="salutation" id="salutation1" value="Dr" onChange={(e) => handleChange(e)} checked={values.salutation === "Dr"} className="form-check-input custom-border-84-20 m-0" />
                        <label className="form-check-label font-16 font-500 ms-2">Dr.</label>
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-3 col-auto mb-30">
                      <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                        <input type="radio" name="salutation" id="salutation2" value="Mr" onChange={(e) => handleChange(e)} checked={values.salutation === "Mr"} className="form-check-input custom-border-84-20 m-0" />
                        <label className="form-check-label font-16 font-500 ms-2">Mr</label>
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-3 col-auto mb-30">
                      <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                        <input type="radio" name="salutation" id="salutation3" value="Ms" onChange={(e) => handleChange(e)} checked={values.salutation === "Ms"} className="form-check-input custom-border-84-20 m-0" />
                        <label className="form-check-label font-16 font-500 ms-2">Ms</label>
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-3 col-auto mb-30">
                      <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                        <input type="radio" name="salutation" id="salutation4" value="Mrs" onChange={(e) => handleChange(e)} checked={values.salutation === "Mrs"} className="form-check-input custom-border-84-20 m-0" />
                        <label className="form-check-label font-16 font-500 ms-2">Mrs</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-30">
                  <label className="form-label m-0 mb-1 font-16 font-500 w-100">First Name</label>
                  <input type="text" className="form-control flex-1" id="First Name" name="fname" error={errors.fname} value={values.fname} onChange={(e) => handleChange(e)} placeholder="Ex: Ashish" />
                </div>
                <div className="col-md-6 mb-30">
                  <label className="form-label m-0 mb-1 font-16 font-500 w-100">Last Name</label>
                  <input type="text" className="form-control flex-1" id="Last Name" name="lname" error={errors.lname} value={values.lname} onChange={(e) => handleChange(e)} placeholder="Ex: Saxena" />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mb-30">
                  <label className="form-label m-0 mb-1 font-16 font-500 w-100">Designation:</label>
                  <input type="text" className="form-control flex-1" id="designation" name="designation" error={errors.designation} value={values.designation} onChange={(e) => handleChange(e)} placeholder="Ex: School Counselor" />
                </div>
                {/* <div className="col-md-6 mb-30">
                  <label className="form-label m-0 mb-1 font-16 font-500 w-100">
                    Campus
                  </label>
                  <select
                    className="form-select form-control country-code"
                    name="DesignationCoun"
                    aria-label="Default select example"
                    required=""
                  >
                    <option>Select</option>
                    <option value="Option1">+92</option>
                    <option value="Option2">+93</option>
                    <option value="Option3">+94</option>
                    <option value="Option4">+95</option>
                  </select>
                </div> */}
              </div>
              <div className="row">
                <div className="col-md-12">
                  <label className="form-label m-0 mb-1 font-16 font-500 w-100">Gender:</label>
                  <div className="row">
                    <div className="col-md-2 col-sm-3 col-auto mb-30">
                      <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                        <input type="radio" name="gender" id="gender_male" value="Male" onChange={(e) => handleChange(e)} checked={values.gender === "Male"} className="form-check-input custom-border-84-20 m-0" />
                        <label className="form-check-label font-16 font-500 ms-2">Male</label>
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-3 col-auto mb-30">
                      <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                        <input type="radio" name="gender" id="gender_female" value="Female" onChange={(e) => handleChange(e)} checked={values.gender === "Female"} className="form-check-input custom-border-84-20 m-0" />
                        <label className="form-check-label font-16 font-500 ms-2">Female</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-30">
                  <label className="form-label m-0 mb-1 font-16 font-500 w-100">Which country do you presently reside in?</label>
                  <select className="form-select" aria-label="Default select example" name="home_country" value={values.home_country} onChange={(e) => handleChange(e)}>
                    <option value="">Select</option>
                    {contryData?.map((a) => {
                      return <option value={a.name}>{a.name}</option>
                    })}
                  </select>
                </div>
                <div className="col-md-6 mb-30">
                  <label className="form-label m-0 mb-1 font-16 font-500 w-100">Current City of Residence</label>
                  <select className="form-select" aria-label="Default select example" name="home_city" value={values.home_city} onChange={(e) => handleChange(e)}>
                    <option value="">Select City</option>
                    {cityData?.map((a, i) => {
                      return <option value={a.name}>{a.name}</option>
                    })}
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-30">
                  <label className="form-label m-0 mb-1 font-16 font-500 w-100">Your Contact Email</label>
                  <input type="text" className="form-control flex-1" id="MinExp" name="email" value={values.email} error={errors.email} onChange={(e) => handleChange(e)} placeholder="Ex: abc@xyz.com" />
                </div>
                <div className="col-md-6 mb-30">
                  <label className="form-label m-0 mb-1 font-16 font-500 w-100">Mobile number (with country code)</label>
                  <div className="row g-2">
                    <div className="col-auto">
                      <select className="form-select form-control country-code" aria-label="Default select example" required="" name="phone">
                        <option>+91</option>
                        <option value="Option1">+92</option>
                        <option value="Option2">+93</option>
                        <option value="Option3">+94</option>
                        <option value="Option4">+95</option>
                      </select>
                    </div>
                    <div className="col">
                      <input type="text" className="form-control" id="PhoneNumber" name="phone" value={values.phone} onChange={(e) => handleChange(e)} placeholder="Ex: 7701898761" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-end align-items-center">
                <div className="my-2">
                  <button type="submit" className="btn btn-green max-200 text-white mx-auto d-block height-50">
                    <span>Update</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EditBasicInfo
